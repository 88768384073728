export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '',
  headline: 'Partner with us',
  description:
    'Are you a food lover or looking for a business proposition to invest money in? We might just be the right choice for you if you care to read further. At Pure Marathi, we are already building a brand that represents authentic Maharashtrian cuisines. We have our very first outlet in the very Maharashtrian town of Mumbai, that is Dombivli. We plan to increase our presence through a structured franchise network. Our plan, however, is different from other franchise setups. We plan to bring our expertise and your money to grow the Pure Marathi Brand. Our rich experience in the F & B industry means we bring you the best to offer, considering and covering every operational, functional, and marketing loophole that might appear otherwise. Our expert teams would secure every investment only towards growth and not experimentation as any novice taking a franchise would.',
  path:'/',
  buttonLabel: 'Back To Home',
  imgStart: '',
  img: 'images/svg-1.svg',
  alt: 'Credit Card'
};

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headlineP: 'Invest and earn your returns as we grow together',
  description:
    'Our business models have been designed such that we will be your front-end, back-end and all the supplementary support. You will be informed regularly on the performance of the unit(s) you have invested in and your returns. This way, you save your time by investing in experts. Simply reach out to us with your queries and our team will be happy to guide you towards a planned way to grow your money.',
  buttonLabel: 'Back To Home',
  path:'/',
  imgStart: '',
  img: 'images/svg-5.svg',
  alt: 'Vault'
};

