import React from 'react';
import './OBPI.css';

const STYLES = ['OBPI--primary', 'OBPI--outline'];

const SIZES = ['OBPI--medium', 'OBPI--large', 'OBPI--mobile', 'OBPI--wide'];

const COLOR = ['primary', 'blue', 'red', 'green'];

export const OBPI = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  buttonColor
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  const checkButtonColor = COLOR.includes(buttonColor) ? buttonColor : null;

  return (
    <>
      <button
        className={`OBPI ${checkButtonStyle} ${checkButtonSize} ${checkButtonColor}`}
        onClick={onClick}
        type={type}
      >
        {children}
      </button>
    </>
  );
};
