import React from 'react';
import HeroSection from '../../HeroSection';
import PartnerSection from '../../PartnerSection';
import AboutSection from '../../AboutusSection';
import Brand from '../../Brand';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';

function Home() {
  return (
    <>
      <Brand {...homeObjOne} />
      <HeroSection {...homeObjThree} />
      <PartnerSection {...homeObjTwo} />
      <AboutSection {...homeObjFour} />
    </>
  );
}

export default Home;
