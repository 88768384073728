import React from 'react';
import PartnerIn from '../../Innerpages/PartnerIn';
import PartnerIt from '../../Innerpages/PartnerIt';
import {  homeObjOne, homeObjTwo } from './Data';

function Partner() {
  return (
    <>
      <PartnerIn {...homeObjOne} />
      <PartnerIt {...homeObjTwo} />
    </>
  );
}

export default Partner;
