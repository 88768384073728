export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Satyaas प्युअर मराठी ',
  headline1: 'By',
  headline: 'Chef Satyendra Jog',
  description: "The Maratha Kingdom is a known history worldwide and so is the flavour of Maharashtrian traditional cuisines. But, with the daily hustle, the significance of traditional cuisines is somewhere getting lost in everyday life. Busy schedules and elaborate preparations have left no time to explore the authentic traditional cuisines. ",
  path: '/brand',
  path1: '/Order',
  buttonLabel: 'Know More',
  order: 'Order Now',
  img: 'images/logo.png',
  alt: 'Credit Card'
};

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  headline: 'Partner With Us',
  description: 'At Pure Marathi, we are already building a brand that represents authentic Maharashtrian cuisines. We have our very first outlet in the very Maharashtrian town of Mumbai, that is Dombivli. We plan to increase our presence through a structured franchise network. Our plan, however, is different from other franchise setups.',
  order: 'join Us',
  path: '/Partner',
  path1: '/form',
  buttonLabel: 'Be A Partner',
  img: 'images/svg-5.svg',
  alt: 'Vault'
};

export const homeObjThree = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  headline: 'Menu',
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  path: '/Menu',
  path1: '/Order',
  buttonLabel: 'View Menu',
  order: 'Order Now',
  img: 'images/svg-7.svg',
  zlogo: 'images/zomato.svg',
  slogo: 'images/swiggy.svg',
  alt: 'Vault'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  headline: 'About Us',
  description:
    'Satyaas–Pure Marathi is an initiative of Chef Satyendra Jog. Satyendra brings more than 18 years of experience from the Hotel management industry, having worked with hotels, restaurants, resorts, Fast food, Specialty, and Quick food domains over these years.',
  order: 'Order Now',
  path1: '/Order',
  path: '/About_Us',
  buttonLabel: 'Know More',
  img: 'images/svg-8.svg',
  alt: 'Vault'
};
