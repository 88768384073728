import React from 'react';
import './AboutusSection.css';
import { ButtonA } from './ButtonA';
import { Link } from 'react-router-dom';
import { OBa } from './Buttons/OBa';



function AboutusSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  order,
  buttonLabel,
  path,
  path1,
  img,
  alt,
}) {
  
  return (
    <>
      <div
        className={lightBg ? 'home__hero-sectiona newBg' : 'home__hero-sectiona darkBg'}
      >
        <div className='containera'>
          <div
            className='rowa home__hero-rowa'
            style={{
              display: 'flex',
            }}
          >
            <div className='cola'>
              <div className='home__hero-text-wrappera'>
                <div className='top-line'>{topLine}</div>
                <h1 className={lightText ? 'headinga' : 'headinga dark'}>
                  {headline}
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitlea'
                      : 'home__hero-subtitlea dark'
                  }
                >
                  {description}
                </p>
                <Link to={path}>
                  <ButtonA buttonSize='btn3--wide' buttonColor='blue' >
                    {buttonLabel}
                  </ButtonA>
                  </Link>
                  <Link to={path1}>
                    <OBa buttonSize='OBA--wide' buttonColor='blue'>
                      {order}
                    </OBa>
                  </Link>
              </div>
              {/* --------------------- */}

            </div>
            <div className='cola'>
              <div className='home__hero-img-wrappera'>
                <img src={img} alt={alt} className='home__hero-imga' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutusSection;
