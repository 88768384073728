import React from 'react';
import './HeroSection.css';
import { ButtonM } from './ButtonM';
import { OBM } from './Buttons/OBM';
import { Link } from 'react-router-dom';
import img1 from './images/Product-1.jpg'
import img2 from './images/Product-c2.jpg'
import img3 from './images/Product-c3.jpg'

const buttonLabel = document.querySelector("#buttonLabel");

function HeroSection({
  lightBg,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  order,
  path,
  path1,
  img,
  zlogo,
  slogo,
  alt,
}) {
  return (
    <>
      <div
        className={lightBg ? 'home__hero-section newBg' : 'home__hero-section darkBg'}
      >
        <div className='container'>
          <div
            className='row home__hero-row'
            style={{
              display: 'flex',
            }}
          >
            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <h1 className={lightText ? 'heading' : 'heading dark'}>
                  {headline}
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle'
                      : 'home__hero-subtitle dark'
                  }
                >
                  {description}
                </p>
                <div className='btm'>
                  <Link to={path}>
                    <ButtonM buttonSize='btn2--wide' buttonColor='blue' >
                      {buttonLabel}
                    </ButtonM>
                    <Link to={path1}>
                      <OBM buttonSize='OBM--wide' buttonColor='blue' >
                        {order}
                      </OBM>
                    </Link>
                  </Link>
                </div>
              </div>
              {/* --------------------- */}

            </div>
            <div className='col1'>
              <div className='pic-ctn'>
                <img src={img1} alt="v" className='pic' />
                <img src={img2} alt="c" className='pic' />
                <img src={img3} alt="m" className='pic' />
                <img src={img2} alt="c" className='pic' />
                <img src={img1} alt="v" className='pic' />
              </div>
            </div>
            <div className='col2'>
              <div className='zomato'>
                <h2 className='zline'>Available on</h2>
                <div className='logos'>
                  <img src={zlogo} className='zlogo' alt="zomato" />
                  <img src={slogo} className='slogo' alt='swiggy' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
