import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/'>Careers</Link>
            <Link to='/'>Terms of Service</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/form'>Contact</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items1'>
            <h2>Social Media</h2>
            <a
            href="https://www.instagram.com/satyaaspuremarathi/"
            >Instagram</a>
            <a
            href='https://www.facebook.com/Satyaas-Pure-Marathi-111040147941798'
            >Facebook</a>
            {/* <Link to='/'>Youtube</Link>
            <Link to='/'>Twitter</Link> */}
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              {/* <img src = {Logo} alt = "Logo" className='navbar-icon' /> */}
              Satyaas प्युअर मराठी
            </Link>
          </div>
          <small className='website-rights'>Satyaas प्युअर मराठी © 2021</small>
          <div className='social-icons'>
            <a
              className='social-icon-link'
              rel="noreferrer"
              href='https://www.facebook.com/Satyaas-Pure-Marathi-111040147941798'
              target='_blank'
              aria-label='Facebook'
            >
              <FaFacebook />
            </a>
            <a
              className='social-icon1-linkInsta'
              rel="noreferrer"
              href="https://www.instagram.com/satyaaspuremarathi/"
              target='_blank'
              aria-label='Instagram'
            >
              <FaInstagram />
            </a>
            {/* <Link
              className='social-icon-link'
              to={
                '//www.youtube.com/channel/UCsKsymTY_4BYR-wytLjex7A?view_as=subscriber'
              }
              target='_blank'
              aria-label='Youtube'
            >
              <FaYoutube />
            </Link>
            <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <FaTwitter />
            </Link>
            <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <FaLinkedin />
            </Link> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
