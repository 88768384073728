import React from 'react';
import './PartnerIn.css';
import { OBPI } from './OBPI';
import { Link } from 'react-router-dom';
// import { OBP } from './Buttons/OBP';

const buttonLabel = document.querySelector("#buttonLabel");

function PartnerIn({
  lightBg,
  lightText,
  lightTextDesc,
  headline,
  headlineP,
  description,
  buttonLabel,
  path,
  img,
  alt,
}) {
  return (
    <>
      <div
        className={lightBg ? 'home__hero-sectionp newBg' : 'home__hero-sectionp darkBg'}
      >
        <div className='containerpi'>
          <div
            className='rowpi home__hero-rowpi'
            style={{
              display: 'flex',
            }}
          >
            <div className='colpi'>
              <div className='home__hero-text-wrapperpi'>
                <h1 className={lightText ? 'headinghpi' : 'headinghpi dark'}>
                  {headline}
                </h1><h1 className={lightText ? 'headingPi' : 'headingPi dark'}>
                  {headlineP}
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitlepi'
                      : 'home__hero-subtitlepi dark'
                  }
                >
                  {description}
                </p>
                <Link to={path}>
                  <OBPI buttonSize='OBPI--wide' buttonColor='blue' >
                    {buttonLabel}
                  </OBPI>
                  {/* <Link>
                    <OBP buttonSize='OBP--wide' buttonColor='blue' >
                    {order}
                    </OBP>
                  </Link> */}
                </Link>
              </div>
              {/* --------------------- */}
              
            </div>
            <div className='colpi'>
              <div className='home__hero-img-wrapperpi'>
                <img src={img} alt={alt} className='home__hero-imgpi' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PartnerIn;
