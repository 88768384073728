export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'About Us',
  // headline: 'Unlimited Transactions with zero fees',
  description: "Satyaas–Pure Marathi is an initiative of Chef Satyendra Jog. Satyendra brings more than 18 years of experience from the Hotel management industry, having worked with hotels, restaurants, resorts, Fast food, Specialty, and Quick food domains over these years. Satyendra is a Turnaround Consultant for sick and dying F & B units and revamps them with his professional expertise and need-based analysis. His venture SE Hoteliers revives F & B units with expert consultation, customized sick unit consultancy, staff training, menu planning, food designing, operations planning, Hotel and restaurant consultancy, branding/ re-branding, marketing, hotel & restaurant interior design initiatives as well as turnkey projects for hotels, restaurants, and resorts. Satyendra's initiative Satyaas Pure Marathi is his hardcore love towards authentic Maharashtrian cuisine, and he believes it should be restored to its full glory. Satyendra has achieved a lot of feats in his culinary experiences and one amongst them is–He has a world record for preparing 25,101 batata wada in a record time of 10 hours and 22 minutes. He also established his brand – Pure Marathi on the same day of the record, out of his love for authentic Maharashtrian cuisine. He says Pure Marathi recipes will bring you the nostalgia of your grandmother's food.",
  buttonLabel: 'Back To Home',
  order: 'Order Now',
  path:'/',
  // imgStart: '',
  // img: 'images/svg-1.svg',
  // alt: 'Credit Card'
};

