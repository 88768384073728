export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  headline: 'Local flavour international standards',
  description: "At Pure Marathi, we are happy to bring back these authentic Maharashtrian dishes for our food lovers. We value your time but know your desire for authentic food. Our Pure Marathi recipes are just that flavour you must have been served by your mother and grandmother, but we bring a certain twist.",
  path: '/',
  buttonLabel: 'Back To Home',
  order: 'Order Now',
  // img: 'images/logo2.png',
  alt: ''
};

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  headline: 'We are getting you back flavours and memories that are lost to time',
  description: "Our menu will cover the most exotic items that surely have seekers for their traditional and authentic roots. The menu is designed keeping in mind the highest sought recipes that are not easily available elsewhere, and that's what makes our brand special.",
  path: '/',
  buttonLabel: 'Back To Home',
  order: 'Order Now',
  img: 'images/svg-5.svg',
  alt: 'Vault'
};

export const homeObjThree = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  headline: 'When tradition matters think Pure Marathi',
  description: 'So, for the tantalizing taste buds, we are all set to serve. We are looking for people who would like to associate with us in reclaiming this authentic food journey. We are looking for investors who would want to bring good returns for themselves as well as be a part of a brand that will be a well-known name.',
  path: '/',
  buttonLabel: 'Back To Home',
  order: 'Order Now',
  img: 'images/svg-8.svg',
  alt: 'Vault'
};

export const homeObjFour = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  headline: 'We are more than a food outlet; we are an experience',
  description:
    "Our attempt at traditional food will be modern, though. We are introducing cafes with a soothing ambience that would serve authentic Maharashtrian food at the most economical pricing. If you think you have the intent and input to join us on this mission of bringing authentic Maharashtrian cuisines to serve, we suggest you take a peek into our accomplishments and our endeavours to know of our potential as a future brand.",
  path: '/',
  buttonLabel: 'Back To Home',
  order: 'Order Now',
  img: 'images/svg-7.svg',
  alt: 'Vault'
};

