import React from 'react';
import Grid from '@material-ui/core/Grid';

import Product from './Product/Product';
import useStyles from './styles';
import './Products.css'
import { Container, Typography } from '@material-ui/core';

const Products = ({ categories, onAddToCart }) => {
  const classes = useStyles();

  if (!categories.length) return <p>Loading...</p>;

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Container id="products">
        {categories.map(category => {
          return (
            <Container>
              <Typography className="headline" variant="h3" component="h2">
                {category.name}
              </Typography>
              <Grid container spacing={4}>
                {category.productsData.map((product) => (
                  <Grid key={product.id} item xs={12} sm={6} md={4} lg={3}>
                    <Product product={product} onAddToCart={onAddToCart} />
                  </Grid>
                ))}
              </Grid>
            </Container>
          );
        })}
        </Container>
    </main>

    // <div>
      
    // </div>

  );
};

export default Products;

