import React from 'react';
import './form.css';

const form = props => {
    return (
      <div className='for'>
        <div className='forin'>
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfCybI6cro-8aPia4S0I_4vqD3OANJhEhwuUExl7_1yQwgorA/viewform?embedded=true" width="640" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>  
      </div>
    )
}

export default form