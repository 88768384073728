import React from 'react';
import './ButtonM.css';

const STYLES = ['btn2--primary', 'btn2--outline'];

const SIZES = ['btn2--medium', 'btn2--large', 'btn2--mobile', 'btn2--wide'];

const COLOR = ['primary', 'blue', 'red', 'green'];

export const ButtonM = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  buttonColor
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  const checkButtonColor = COLOR.includes(buttonColor) ? buttonColor : null;

  return (
    <button
      className={`btn2 ${checkButtonStyle} ${checkButtonSize} ${checkButtonColor}`}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};
