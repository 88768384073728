import React from 'react';
import { homeObjOne } from './Data';
import AboutusIn from '../../Innerpages/AboutIn';

function AboutUp() {
  return (
    <>
      <AboutusIn {...homeObjOne} />
    </>
  );
}

export default AboutUp;
