import React from 'react';
import './Order.css';
import zoo from './zomato.svg';
import soo from './swiggy.svg';

function getRadioValue() {
    let i = 0;
    let radio = document.getElementsByName('flexRadioDefault');
    for (i = 0; i < radio.length; i++) {
        if (radio[i].checked)
            window.open(radio[i].value)
    }
}



const Order = props => {
    return (
        <div className='odr'>
            <div className='odrin'>
                <div className='odrins'>
                    <div className='odrinn'>
                        <div className="form-check1">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="https://satyaaspuremarathi.dotpe.in/order" checked />
                            <label className="form-check-label" for="flexRadioDefault1">
                                Order Here
                            </label>
                        </div>
                        <div className="form-check2">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="https://www.swiggy.com/search?q=Satyaas+Pure+Marathi" checked />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Swiggy
                            </label>
                            <img src={soo} className='soo' alt="zomato" />
                        </div>
                        <div className="form-check3">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" value="https://www.zomato.com/mumbai/restaurants/satyaas-pure-marathi?subzone=83631" checked />
                            <label className="form-check-label" for="flexRadioDefault3">
                                Zomato
                            </label>
                            <img src={zoo} className='zoo' alt="zomato" />
                        </div>
                        <button className="ob" onClick={getRadioValue}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Order