import React from 'react';
import BrandIn from '../../Innerpages/BrandIn';
import BrandIt from '../../Innerpages/BrandIt';
import BrandIth from '../../Innerpages/BrandIth';
import BrandIf from '../../Innerpages/BrandIf';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';

function Brand() {
  return (
    <>
      <BrandIn  {...homeObjOne} />
      <BrandIt  {...homeObjTwo} />
      <BrandIth  {...homeObjThree} />
      <BrandIf  {...homeObjFour} />
    </>
  );
}

export default Brand;
