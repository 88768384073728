import React from 'react';
import './Brand.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import { OBB } from './Buttons/OBB';

const buttonLabel = document.querySelector("#buttonLabel");

function BrandSection({
  lightBg,
  topLine,
  headline1,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  order,
  path,
  path1,
  img,
  alt,
}) {
  return (
    <>
      <div
        className={lightBg ? 'home__hero-section1 newBg' : 'home__hero-section1 darkBg'}
      >
        <div className='container1'>
          <div
            className='row1 home__hero-row1'
            style={{
              display: 'flex',
            }}
          >
            <div className='col1'>
              <div className='home__hero-text-wrapper1'>
                <div className='top-line1'>{topLine}</div>
                <div className={lightTextDesc ? 'heading2' : 'heading2 dark'
                  }>{headline1}</div>
                <h1 className={lightText ? 'heading1' : 'heading1 dark'}>
                  {headline}
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle1'
                      : 'home__hero-subtitle1 dark'
                  }
                >
                  {description}
                </p>
                <div className='bts'>
                <Link to={path}>
                  <Button buttonSize='btnH--wide' buttonColor='blue' >
                    {buttonLabel}
                  </Button>
                </Link>
                <Link to={path1}>
                    <OBB buttonSize='OBB--wide' buttonColor='blue' >
                    {order}
                    </OBB>
                  </Link>
                </div>
              </div>
              {/* --------------------- */}

            </div>
            <div className='col1'>
              <div className='home__hero-img-wrapper1'>
                <img src={img} alt={alt} className='home__hero-img1' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BrandSection;
