import React from 'react';
import './OBBI.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from 'react'
import { Modal } from 'react-bootstrap';

const STYLES = ['OBBI--primary', 'OBBI--outline'];

const SIZES = ['OBBI--medium', 'OBBI--large', 'OBBI--mobile', 'OBBI--wide'];

const COLOR = ['primary', 'blue', 'red', 'green'];

export const OBBI = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  buttonColor
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  const checkButtonColor = COLOR.includes(buttonColor) ? buttonColor : null;
  return (
      <button
        className={`OBBI ${checkButtonStyle} ${checkButtonSize} ${checkButtonColor}`}
        onClick={onClick}
        type={type}
      >
        {children}
      </button>
  );
};
