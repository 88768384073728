import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/pages/HomePage/Home';
import About_us from './components/pages/About Us/About_us';
// import Products from './components/pages/Menu/Products';
import Products from './components/Products/Products'
import SignUp from './components/pages/SignUp/SignUp';
import Partner from './components/pages/Partner/Partner';
import form from './components/form';
import Navbar from './components/Navbar';
import Footer from './components/pages/Footer/Footer';
import Brand from './components/pages/Brand/Brand';
import Order from './components/Order';
import ScrollToTop from './components/ScrollToTop';
import { commerce } from './lib/commerce';




function App() {
  const [categories, setCategories] = useState([]);

  const fetchProducts = async () => {
    const { data: products } = await commerce.products.list({ limit: 200 });
    const { data: categoriesData } = await commerce.categories.list({ limit: 200 });


    const productsPerCategory = categoriesData.reduce((acc, category) => {
      return [
        ...acc,
        {
          ...category,
          productsData: products.filter((product) =>
            product.categories.find((cat) => cat.id === category.id)
          ),
        },
      ];
    }, [])

    setCategories((productsPerCategory) || []);
  };

  useEffect(() => {
    fetchProducts();
  }, []);



  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/About_Us' component={About_us} />
        <Route path='/brand' component={Brand} />
        <Route path='/Menu'>
          <Products categories={categories}/>
        </Route>
        <Route path='/form' component={form} />
        <Route path='/Order' component={Order} />
        <Route path='/Partner' component={Partner} />
        <Route path='/sign-up' component={SignUp} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
