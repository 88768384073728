import React from 'react';
import './BrandIt.css';
import { OBBI } from './OBBI';
import { Link } from 'react-router-dom';
// import { OBB } from './Buttons/OBB';

const buttonLabel = document.querySelector("#buttonLabel");

function BrandIt({
  lightBg,
  topLine,
  headline1,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  path,
  img,
  alt,
}) {
  return (
    <>
      <div
        className={lightBg ? 'home__hero-section1 newBg' : 'home__hero-section1 darkBg'}
      >
        <div className='container1'>
          <div
            className='row1 home__hero-row1'
            style={{
              display: 'flex',
            }}
          >
            <div className='col1'>
              <div className='home__hero-text-wrapperi1'>
                <div className='top-line1'>{topLine}</div>
                <div className={lightTextDesc ? 'headingt2' : 'headingt2 dark'
                  }>{headline1}</div>
                <h1 className={lightText ? 'headingt2' : 'headingt2 dark'}>
                  {headline}
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitlet1'
                      : 'home__hero-subtitlet1 dark'
                  }
                >
                  {description}
                </p>
                <Link to={path}>
                  <OBBI buttonSize='OBBI--wide' buttonColor='blue' >
                    {buttonLabel}
                  </OBBI>
                </Link>
                {/* <Link>
                    <OBB buttonSize='OBB--wide' buttonColor='blue' >
                    {order}
                    </OBB>
                </Link> */}
              </div>
              {/* --------------------- */}

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BrandIt;
