import React from 'react';
import './OBAI.css';
import "bootstrap/dist/css/bootstrap.min.css";


const STYLES = ['OBaI--primary', 'OBaI--outline'];

const SIZES = ['OBaI--medium', 'OBaI--large', 'OBaI--mobile', 'OBaI--wide'];

const COLOR = ['primary', 'blue', 'red', 'green'];

export const OBaI = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  buttonColor
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  const checkButtonColor = COLOR.includes(buttonColor) ? buttonColor : null;
  return (
    <button
      className={`OBaI ${checkButtonStyle} ${checkButtonSize} ${checkButtonColor}`}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};
