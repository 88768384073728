import React from 'react';
import './OBA.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from 'react'
import { Modal } from 'react-bootstrap';

const STYLES = ['OBA--primary', 'OBA--outline'];

const SIZES = ['OBA--medium', 'OBA--large', 'OBA--mobile', 'OBA--wide'];

const COLOR = ['primary', 'blue', 'red', 'green'];

export const OBa = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  buttonColor
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  const checkButtonColor = COLOR.includes(buttonColor) ? buttonColor : null;
  const [isOpen, setIsOpen] = React.useState(false);
  const showModal = () => {
    setIsOpen(true);
  };
  const hideModal=() => {
    setIsOpen(false);
  };
  return (
    <>
    <button
      className={`OBA ${checkButtonStyle} ${checkButtonSize} ${checkButtonColor}`}
      onClick={showModal}
      type={type}
    >
      {children}
    </button>
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Hi</Modal.Title>
        </Modal.Header>
        <Modal.Body>Site is Under Devlopment</Modal.Body>
        <Modal.Footer>
          <button onClick={hideModal}>Cancel</button>
        </Modal.Footer>
      </Modal>
      </>
  );
};
